.ctab {
	border:none!important;
	font-size: 14px !important;
	line-height: 17px !important;
	letter-spacing: -0.5px !important;
	color: #94979b !important;
	height: 60px;
	position: relative;
	display: flex;
	align-items: center;
	padding-left: 30px;
	padding-right: 30px;
	margin-right: 20px;

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		height: 3px;
		width: 100%;
		left: 0;
	}

	&.active {
		color: var(--c-green) !important;
		&::after {
			background-color:  var(--c-green);
		}
	}
}
