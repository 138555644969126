.bs-table {
	border-collapse: collapse;
	border-spacing: 0 15px;
	> thead {
		> tr {
			> th {
				border: none;
				background: #f4f4f4;
				font-weight: 500;
				font-size: 1rem;
				letter-spacing: -0.5px;
				padding: 10px 2px;
				// &:not(.bs-table-col-header-name) {
				// 	text-align: center;
				// }

				&:first-child {
					border-top-left-radius: 6px;
					border-bottom-left-radius: 6px;
					padding-left: 1.875rem;
				}

				&:last-child {
					border-top-right-radius: 6px;
					border-bottom-right-radius: 6px;
				}
			}
		}
	}

	&-col-action {
		width: 300px;
	}
}

tbody tr,
tr.bs-table-body {
	background: #ffffff;
	height: 80px;
	border-radius: 6px;

	td {
		border: none;
		vertical-align: middle;
		font-size: 0.9375rem;

		&:first-child {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			padding-left: 1.875rem;
		}
		&:last-child {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}

	td.react-bs-table-no-data {
		vertical-align: middle;
	}
}

// .bs-table-col-first {
// 	font-size: 14px;
// 	color: #0b1939;
// 	font-weight: 500;
// 	font-family: "Montserrat", sans-serif;
// }
// .bs-table-col-date {
// 	font-size: 14px;
// 	color: #0b1939;
// 	font-weight: 300;
// 	font-family: "Montserrat", sans-serif;
// }
// .bs-expanding-row {
// 	background-color: #f4f6f8;
// }
// .expanded-first-td {
// 	width: 12.3%;
// }
// .expanded-td {
// 	font-size: 14px;
// 	color: #0b1939;
// 	font-weight: 300;
// }
// .bs-table-col-action {
// 	display: none;
// }
