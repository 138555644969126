@import "../../styles/global.scss";

.auth {
	&-fcol {
		background-color: var(--c-d-blue);
		height: 100vh;
		padding: 50px 50px 50px 34px;

		&_title,
		&_content {
			font-size: 30px;
			line-height: 35px;
			letter-spacing: -1px;
			color: var(--white);
			margin-bottom: 60px;
		}
	}

	&-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		min-width: 100%;
		min-height: 100vh;
		background-color: var(--white);
	}
	&-content {
		position: relative;
		max-width: 564px;
		width: 100%;
		padding: 15px;
		z-index: 5;
	}

	&-card {
		background: rgba(221, 224, 227, 0.2);
		border-radius: 6px;
		padding: 3rem 1rem;
		border: none;
		margin-bottom: 1.6rem;

		@media screen and (min-width: $breakpoint-lg) {
			padding: 3rem 4rem;
		}

		&-title {
			font-weight: 700;
			font-size: 2.25rem;
			line-height: 2.6875rem;
			letter-spacing: -1px;
			color: var(--c-black);
			text-align: center;
			margin-bottom: 1.75rem;

			@media screen and (min-width: $breakpoint-lg) {
				font-size: 3rem;
				margin-bottom: 2.125rem;
			}
		}

			&-subtext {
			font-weight: 300;
			font-size: 15px;
			line-height: 18px;
			letter-spacing: -1px;
			color: var(--c-black);
		}

		&-footLink {
			font-size: 1rem;
			letter-spacing: -0.5px;
			color: var(--c-black);

			a {
				font-weight: 700;
				color: var(--c-black);
			}
		}
	}

	&-phone {
		height: 62px !important;
		width: 100% !important;
		border: 1px solid #c4c4c4 !important;
		background-color: transparent !important;
		border-radius: 10px !important;
		font-size: 1rem !important;
		line-height: 1.625rem !important;
		letter-spacing: -1px !important;
		// padding-left: 88px !important;
		font-family: "Rubik", sans-serif !important;

		&_btn {
			border-radius: 10px 0 0 10px !important;
			width: 80px;
			display: flex;
			align-items: center;
			border-right: none !important;

			.selected-flag {
				padding: 0 0 0 28px !important;
				width: 100% !important;

				&:hover {
					background-color: transparent !important;
				}
			}
		}
	}
}
