@import "~normalize.css/normalize.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&family=Manrope:wght@400;500;600;700;800&display=swap");
@import './styles/global.scss';

:root {
	--sidebar-width: 224px;
	--c-black: #000000;
	--c-d-blue: #010933;
	--c-green: #3AB75D;
	--c-grey: #878787;
	--c-red: #F90217;
	--c-darken-red: #da0517;
	--c-darken-green: #33984f;
}

body {
	margin: 0;
	font-family: "Rubik", -apple-system, BlinkMacSystemFont,
		"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ECEFF4;
}

.object-fit-cover {
	object-fit: cover;
}

.object-position {
	object-position: center;
}

.cs-content {
	border-radius: 0;
	border: 1px solid #C4C4C4;
}
.error{
	color: red;
	font-size: 12px;
}